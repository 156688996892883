<script>
import { mapState } from 'vuex'

import { toastMixins } from '@/mixins'
import ge2gjnlService from '@/services/entry/ge2gjnl.js'

import MenuBar from './components/MenuBar.vue'
import HeaderForm from '../components/HeaderForm.vue'
import DetailForm from '../components/DetailForm.vue'

export default {
  mixins: [toastMixins],
  components: { MenuBar, DetailForm, HeaderForm },
  data() {
    return {
      isLoading: false,
      header: {},
      headerForUpdate: {},
      details: [],
      detailsForUpdate: [],
      errMsg: null
    }
  },
  computed: {
    ...mapState({
      defaultDelayMs: state => state.constants.defaultDelayMs
    })
  },
  methods: {
    headerUpdatedHandler(updatedHeader) {
      this.header = { ...updatedHeader }
    },
    detailsUpdatedHandler(updatedDetails) {
      this.details = [...updatedDetails]
    },
    async confirmButtonClickedHandler() {
      this.isLoading = true
      try {
        const {
          templateCode,
          templateName,
          userIdGroupCode,
          journalCode
        } = this.header

        const body = {
          templateCode,
          templateName,
          userIdGroupCode,
          journalCode,
          details: this.details.map(detail => {
            const { accountNumber, remark, debitAmount, creditAmount } = detail
            return {
              accountNumber,
              remark: remark && remark.trim().length > 0 ? remark : null,
              debitAmount:
                +debitAmount === 0 ? null : (+debitAmount).toFixed(2),
              creditAmount:
                +creditAmount === 0 ? null : (+creditAmount).toFixed(2)
            }
          })
        }
        await ge2gjnlService.createNewJournalDocumentTemplate(body)
        this.mxDisplaySuccessMessage('สร้างต้นแบบป้อนรายการสำเร็จ')
        setTimeout(() => {
          this.$router.push('/entry/ge2gjnl/journal-document-templates')
        }, this.defaultDelayMs)
      } catch (err) {
        const errMessage =
          err.response?.data.thMessage || 'สร้างต้นแบบป้อนรายการไม่สำเร็จ'
        this.errMsg = errMessage
      }
      this.isLoading = false
    },
    templateSelectedHandler(template) {
      this.fetchJournalDocumentTemplateById(template.id)
    },
    async fetchJournalDocumentTemplateById(templateId) {
      this.isLoading = true
      try {
        const res = await ge2gjnlService.getJournalDocumentTemplateById(
          templateId
        )
        const { header, details } = res.data.data
        this.headerForUpdate = { ...header }
        this.detailsForUpdate = [...details]
      } catch (err) {
        const errMessage =
          err.response?.data.thMessage ||
          'ดึงข้อมูลต้นแบบป้อนรายการเดิมไม่สำเร็จ'
        this.errMsg = errMessage
      }
      this.isLoading = false
    }
  }
}
</script>

<template>
  <div>
    <b-container fluid="lg">
      <!-- แถบ menu ด้านบน -->
      <b-row class="d-flex align-items-center justify-content-between">
        <b-col class="col-12 col-xl-auto py-3 text-center text-xl-left">
          <span class="text-xxl text-white" style="text-shadow: 2px 2px grey;">
            สร้างต้นแบบป้อนรายการ
          </span>
        </b-col>
        <b-col
          class="col-12 col-xl-auto py-3 d-flex justify-content-center justify-content-xl-end"
        >
          <menu-bar></menu-bar>
        </b-col>
      </b-row>
    </b-container>

    <b-container fluid>
      <!-- แถบแสดงผลข้อมูล -->
      <b-row class="my-4">
        <b-col cols="12">
          <header-form
            :isLoadingProp="isLoading"
            :operationProp="'CREATE'"
            :headerProp="headerForUpdate"
            @headerUpdated="headerUpdatedHandler"
            @confirmButtonClicked="confirmButtonClickedHandler"
            @templateSelected="templateSelectedHandler"
          ></header-form>
        </b-col>
      </b-row>

      <!-- แถบตาราง -->
      <b-row>
        <b-col cols="12">
          <detail-form
            :operationProp="'CREATE'"
            :detailsProp="detailsForUpdate"
            @detailsUpdated="detailsUpdatedHandler"
          ></detail-form>
        </b-col>
      </b-row>
    </b-container>

    <error-modal
      :displayProp="!!errMsg"
      :errorMessageProp="errMsg"
      @modalClosed="errMsg = null"
    ></error-modal>
  </div>
</template>

<style></style>
